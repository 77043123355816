import React, { useEffect, useContext, useState } from "react"
import SEO from "../components/seo"
import "./index.css"

import { API, graphqlOperation } from "aws-amplify"

import * as buttonStyles from "../components/button.module.css"
import { LoadMaskContext } from "../provider/loadmaskprovider"

import { Notification, NotificationType } from "../components/notification";
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';
import ObservationItem from "../components/observationItem"
import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import { UserContext,AuthContext } from '../provider/usercontextprovider'
import { Auth } from 'aws-amplify'
import CustomAuth from "../components/custom-auth"

const listObservations = /* GraphQL */ `
query SearchObservations($user: String!, $nextToken: String){
	searchObservations(limit: 20, filter: {userId:{
    eq: $user
  }}, nextToken: $nextToken, sort: {field: createdAt, direction: desc}){
    items {
      id
      userId
      tenant
      firstName
      lastName
      email
      result
      category
      productName
      createdAt
    }
    nextToken
  }
}`

const ObservationPage = () => {

  const [isLoading, setIsLoading] = useContext(LoadMaskContext)
  const [state, setState] = useState({msg: null, success: true, results: []})
  const [userContext, setUserContext] = useContext(UserContext)
  const [authState, setAuthState] = useContext(AuthContext)

  useEffect(()=>{
    Auth.currentAuthenticatedUser()
      .then(user => {
        setUserContext(user)
      })
      .catch(err => {
        setUserContext({username: null});
      });
    return onAuthUIStateChange(newAuthState => {
      setAuthState(newAuthState)
      if(newAuthState == 'signedout' && setUserContext){
        setUserContext({username: null});
      }
    });
  },[])

  useEffect(()=>{
    if(userContext.username){
      getObservations()
    }
  },[userContext.username])

  async function getObservations() {
    try {
      setIsLoading(true)

      const result = await API.graphql(graphqlOperation(listObservations, { 
        user: userContext.username,
        nextToken: state.nextToken
      }));
  
      let localState = {
        ...state
      }
      localState.results = localState.results.concat(result.data.searchObservations.items)
      localState.nextToken = result.data.searchObservations.nextToken
  
      setState(localState)

    } catch(e) {
      setState({success: false, results: []})
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <CustomAuth>
      <div>
          <SEO title="Observations" />

          <div>
            <div><h2>Observations</h2></div>
            <div style={{ marginTop: "2rem" }}>
              {state.results  && 
                <div>
                    {state.results.map(r => (
                      <ObservationItem key={r.id} observation={r} />
                    ))}
                </div>
              }
              { !state.success && 
                <Notification messageType={NotificationType.FAIL}>
                  <p>
                  {state.msg}
                  </p>
                </Notification> 
            }
            </div>
            <div style={{ textAlign: "center" }}>
              <button
                  className={buttonStyles.button}
                  style={{
                      display: state.nextToken == null ? "none" : "initial",
                  }}
                  onClick={e => {
                      e.preventDefault()
                      getObservations()
                  }}
              >
                  Load More
              </button>
            </div>
        </div>
      </div>
    </CustomAuth>
  )
}
export default ObservationPage
