import React from "react"
import * as enrollerStyles from "./enroller.module.css"

const ObservationItem = ({observation}) => {
  return (
    <div className={enrollerStyles.resultsItem}>
            <div className={enrollerStyles.mainContent}>
                <p className={enrollerStyles.enrolleeName}>
                    {observation.firstName} {observation.lastName}
                    <span className={enrollerStyles.enrolleeId}>{observation.tenantProvidedUserId ? observation.result : ""}</span>
                </p>
                <p style={{margin: 0, fontSize: "smaller"}}>{observation.email}</p>
                <div className={enrollerStyles.testResult}>
                    <p className={`${observation.result ? enrollerStyles[observation.result] : enrollerStyles.Pending}`}>
                        {observation.result ? observation.result : "Pending"}
                    </p>
                </div>
            </div>
            <div className={enrollerStyles.testDetails}>
              <p>{observation.productName}</p>
                {observation.createdAt &&
                    <div className={enrollerStyles.date}>
                        <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                        Observed {new Date(observation.createdAt).toLocaleDateString()}
                    </div>
                }
                
                <p>{observation.category}</p>
                <p style={{margin: 0, fontSize:"smaller"}}>{observation.email}</p>
            </div>
        </div>
  )
}

export default ObservationItem